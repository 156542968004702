import { datadogRum } from "@datadog/browser-rum";

export const InitalizeDatadog = () => {
  datadogRum.init({
    applicationId: process.env.REACT_APP_DATADOG_APP_ID,
    clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
    site: "datadoghq.eu",
    service: "xcp-homepage",
    env: process.env.REACT_APP_DEPLOYMENT_ENVIRONMENT,
    // Specify a version number to identify the deployed version of your application in Datadog
    version: "1.2.0",
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: "mask-user-input"
  });
};
