import { createContext, useMemo, useState, useContext, useEffect, useRef } from "react";

import { SessionContext, SessionContextValue } from "../Session/SessionContext";
import NotificationComponent, {
  INotificationProps
} from "../../Components/CustomComponents/NotificationComponent/Index";
import HttpRequest, { IRequestProps } from "../../Utilities/ApiHelper/HttpRequest";
import { GeneratePrimeNumbers } from "../../Utilities/PrimeNumbers/PrimeNumber";
import { ReorderWidgets } from "../../Utilities/ReorderWidgets";

export type WidgetContextValue = {
  widget: IWidget[];
  setWidget: React.Dispatch<React.SetStateAction<IWidget[]>>;
  isWidgetApiFailed: boolean;
  setIsWidgetApiFailed: (isApiFailed: boolean) => void;
  widgetItem: IModifiedWidgetItem[];
  setWidgetItem: (widgetItem: IModifiedWidgetItem[]) => void;
  primeNumbers: number[];
  searchWidgetText: string;
  setSearchWidgetText: React.Dispatch<React.SetStateAction<string>>;
  isSearchAvailable: React.MutableRefObject<boolean>;
};

export interface IWidgetItems {
  type?: string;
  id: string;
  attributes: {
    name: string;
    enabled: boolean;
    count: number;
  };
}

export interface IModifiedWidgetItem {
  widgetName: string;
  widgetEnabled: boolean;
  widgetId: string;
  relationshipsId: string[];
  widgetItem: IWidgetItems[];
}

export interface IWidget {
  type?: string;
  id: number;
  attributes: {
    name: string;
    enabled: boolean;
    refreshIntervalSecond: number;
  };
  relationships?: {
    widgetItems: {
      data: {
        type: string;
        id: string;
      }[];
    };
  };
}

export const WidgetContext = createContext<WidgetContextValue | null>(null);

type WidgetProviderProps = {
  children: React.ReactNode;
};

const WidgetProvider = (props: WidgetProviderProps) => {
  const { session } = useContext<SessionContextValue>(SessionContext);
  const [widget, setWidget] = useState<IWidget[]>(undefined);
  const [widgetItem, setWidgetItem] = useState<IModifiedWidgetItem[]>(undefined);
  const [isWidgetApiFailed, setIsWidgetApiFailed] = useState(false);
  const [primeNumbers, setPrimeNumbers] = useState([]);
  const [searchWidgetText, setSearchWidgetText] = useState<string>();
  const isSearchAvailable = useRef<boolean>(false);
  const getWidgetsRequestProps: IRequestProps = {
    session,
    method: "GET",
    url: "/widgets?include=widgetItems"
  };

  const FilterWidget = (widget) => {
    return widget.filter((i) => session.manageWidget.includes(i.attributes.name));
  };

  const AddRefreshIntervalSecond = (primeNumbersOnLoad) => {
    setWidget((widget) => {
      return widget.map((item, index) => {
        return {
          ...item,
          attributes: {
            ...item.attributes,
            refreshIntervalSecond: session.autoRefreshIntervalSeconds + primeNumbersOnLoad[index]
          }
        };
      });
    });
  };

  useEffect(() => {
    if (session.sessionToken !== undefined) {
      HttpRequest(getWidgetsRequestProps)
        .then((res) => {
          let primeNumbersOnLoad: number[];

          if (session.widgetOrder != null && session.manageWidget != null) {
            setWidget(ReorderWidgets(session.widgetOrder, res?.data));
            setWidget((widget) => FilterWidget(widget));
          } else {
            setWidget(res?.data);
          }

          const widgetCount = res.data.length;
          primeNumbersOnLoad = GeneratePrimeNumbers(widgetCount);
          setPrimeNumbers(primeNumbersOnLoad);
          AddRefreshIntervalSecond(primeNumbersOnLoad);
          setWidgetItem(ModifyWidgetItemResponse(res));
        })
        .catch((err) => {
          setWidget([]);
          setIsWidgetApiFailed(err.statusCode !== 404);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session.sessionToken]);

  const value = useMemo(
    () => ({
      widget,
      setWidget,
      isWidgetApiFailed,
      setIsWidgetApiFailed,
      widgetItem,
      setWidgetItem,
      primeNumbers,
      searchWidgetText,
      setSearchWidgetText,
      isSearchAvailable
    }),
    [widget, widgetItem, isWidgetApiFailed, primeNumbers, searchWidgetText, isSearchAvailable]
  );

  const errorNotificationProps: INotificationProps = {
    notificationType: "error",
    isOpen: isWidgetApiFailed,
    onClose: () => setIsWidgetApiFailed(false)
  };

  //Returns the array of widgetItems mapped to the corresponding widgets from response
  const ModifyWidgetItemResponse = (response) => {
    let modifiedWidgetItem: IModifiedWidgetItem[] = response?.data.map((item) => {
      let relationshipsId = item?.relationships.widgetItems.data.map((i) => i.id);
      let objectValue = {
        widgetName: item?.attributes.name,
        widgetEnabled: item?.attributes.enabled,
        widgetId: item?.id,
        relationshipsId: relationshipsId || [],
        widgetItem:
          response.included?.filter((i) => {
            if (relationshipsId.includes(i.id.toString())) {
              return { ...i };
            } else {
              return;
            }
          }) || []
      };
      return { ...objectValue };
    }, []);

    let orderedWidget: IWidget[] =
      session.widgetOrder != null ? ReorderWidgets(session.widgetOrder, response?.data) : response?.data;

    let orderedWidgetItem: IModifiedWidgetItem[] = [];
    orderedWidget.forEach((i: IWidget) => {
      modifiedWidgetItem.forEach((widgetItem: IModifiedWidgetItem) => {
        if (i.attributes.name === widgetItem.widgetName) {
          orderedWidgetItem.push(widgetItem);
        }
      });
    });

    return orderedWidgetItem;
  };

  return (
    <WidgetContext.Provider value={value}>
      {isWidgetApiFailed && <NotificationComponent {...errorNotificationProps} />}
      {props.children}
    </WidgetContext.Provider>
  );
};

export default WidgetProvider;
